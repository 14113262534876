import {useEffect, useState} from "react";
import {sectionIds} from "./sectionIds";

export const NavBar = () => {

    // const [activeLink, setActiveLink] = useState("top")
    const [toggleNavbar, setToggleNavbar] = useState(false)

    const hideMenu = () => {
        setToggleNavbar(false)
    }

    return (
        <nav id="navbar-main" className="navbar fixed-top navbar-expand-lg bg-dark-green shadow-sm">
            <div className="container">
                <a className="navbar-brand" href="#home">
                    <img className="me-2" src="/images/logo.png"/>
                    Symos</a>
                <button className="navbar-toggler navbar-dark" type="button" onClick={() => setToggleNavbar(!toggleNavbar)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${toggleNavbar ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-lg-0">
                        {
                            sectionIds.map((sectionId, i) => (
                                <li key={i}>
                                    <a onClick={hideMenu} className={"nav-link"} href={`#${sectionId.toLowerCase()}`}>{sectionId}</a>
                                </li>
                            ))
                        }
                        {/*<li>{toggleNavbar ? 'Hide' : 'Show'}</li>*/}
                    </ul>
                    <a href="#contact" className="btn btn-success me-2 btn-sm px-4 rounded-4">
                        Get Started
                    </a>
                    <form className="d-flex" role="search">
                            <a href="https://app.symos.io" className="btn btn-outline-success btn-sm me-2 px-4 rounded-4 text-white">Login</a>
                            {/*<a href="#" className="btn btn-warning rounded-5 btn-sm px-4 text-light-green">Login</a>*/}
                    </form>
                </div>
            </div>
        </nav>
    )
}


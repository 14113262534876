import {NicheItem} from "./NicheItem";
import {FeatureCard} from "../features/FeatureCard";
import {NicheList} from "./NicheList";

export const Niche = () => {
    return (
        <section id="niche" className="container">
            <div className="row align-items-center py-5">
                <div className="col-12 text-center">
                    <strong>NICHE</strong>
                    <h1 className="my-4">Why should you choose Symos?</h1>
                </div>
                <div className="row niche-wrapper">
                    {NicheList.map((niche, i) => (
                        <div key={i} className="col-12">
                            <NicheItem {...niche} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export interface IFeature {
    title: string
    icon: string
    bgColor: string
    body: any
    borderRadius?: string
}

export const FeatureList : Array<IFeature> = [
    {
        title: "Farmer\nProfiling",
        bgColor: "#0C933A",
        borderRadius: "3em 0 0 0",
        icon: 'house-fill',
        body: "You can create detailed profiles of your farmers including their farming practices," +
            " landsize, crop varieties, livestock inventory and much more. \n \n" +
            "This holistic view enables better collaboration and support between buyers and farmers, " +
            "fostering sustainable partnerships and driving mutual growth."
    },
    {
        title: "Input\nDistribution",
        bgColor: "#132A13",
        icon: 'stack',
        borderRadius: "0 0",
        body: "Do you give out farm inputs to farmers? How do you keep track of what " +
            "inputs you have given out to which farmer? How do you " +
            "reconcile at harvest time? \n\nThis feature exists specifically to help " +
            "you seamlessly manage this process so that at all times you know what inputs you have given out to your farmers."
    },
    {
        title: 'Inventory\nManagement',
        bgColor: "#075171",
        borderRadius: "0 3em 0 0",
        icon: "database-fill",
        body: "Are you an aggregator? How do you manage your stock? " +
            "Do you have challenges with that? " +
            "Are you able to know your stock levels at all times?\n\n" +
            "This feature has been carefully designed with this in mind. You will no longer run out of stock. You will be able to monitor your stock in realtime."
    },
    {
        title: 'Cost\nTracking',
        bgColor: "#132A13",
        borderRadius: "0 0 0 3em",
        icon: "wallet-fill",
        body: "Are you an agri-processor? Do you want to keep track of all your costs at " +
            "every stage of your processing cycle? Have you tried other systems that didn't work for you?\n\n" +
            "Look no further. Our cost tracking module allows you to set up and customise your " +
            "processes and track all the costs you incur at each step of the process."
    },
    {
        title: 'Brokerage',
        bgColor: "#075171",
        borderRadius: "0 0 0 0",
        icon: "currency-exchange",
        body: "Do you have access to ready market for your produce? Are you struggling with middlemen? " +
            "Do you feel that you aren't paid fairly for your produce?\n\n" +
            "With Symos, you get access to a global market where you can sell your produce to " +
            "buyers that have been verified and profiled with an impeccable track record."
    },
    {
        title: 'Payments',
        bgColor: "#0C933A",
        borderRadius: "0 0 3em 0",
        icon: "credit-card",
        body: "How do you pay your farmers? Do you carry cash every time you pay them? " +
            "How do you mitigate the risks of carrying huge sums of money in cash?\n\n" +
            "With Symos, you can pay all your farmers directly on their <strong>bank account</strong> or <b>mobile money</b> to any network by the click of a button"
    }
]
export const Banner = () => {
    return (
        <section id="home" className="banner">
            <div className="container">
                <div className="row align-items-center vh-100">
                    <div className="col-lg-6 col-12">
                        <div id="top"
                             className="main-text">
                            We transform your<br/><strong className="text-white">Agri-business</strong>,
                            giving you <strong className="text-white">insights</strong> and <strong className="text-white">control</strong>,
                            every step of the process.
                        </div>
                        <a href="#contact" className="btn btn-warning btn-lg rounded-5 px-4 text-light-green mt-5">
                            <strong>Start Now</strong>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
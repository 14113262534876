import {FeatureList} from "./FeatureList";
import {FeatureCard} from "./FeatureCard";

export const Features = () => {
    return (
        <section id="features" className="bg-light-gray py-5 ">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <strong>FEATURES</strong>
                        <h1 className="my-4">What does Symos do?</h1>
                    </div>
                </div>
                <div className="row my-5">
                    {FeatureList.map((feature, i) => (
                        <div key={i} className="col-12 col-lg-4">
                            <FeatureCard {...feature} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export interface INiche {
    title: string
    icon: string
    bgColor: string
    body: any
    borderRadius?: string
    imageSrc?: string
    imagePosition?: 'right' | 'left'
}


export const NicheItem = (props: INiche) => {
    return (
        <div className="mb-5" style={{backgroundColor: props.bgColor}}>
            <div className="row">
                <div className={`col-12 col-lg-5 d-flex align-items-center ${props.imagePosition === 'left' ? 'order-1' : 'order-2'}`}>
                    <div className="image-wrapper">
                        <div className="position-relative">
                            <div className={`position-absolute icon-wrapper ${props.imagePosition === 'left' ? 'icon-wrapper-right' : 'icon-wrapper-left'}`}>
                                <i className={`icon bi bi-${props.icon}`}></i>
                            </div>
                        </div>
                        <img src={props.imageSrc}/>
                    </div>
                </div>
                <div className={`col-12 col-lg-7 d-flex align-items-center ${props.imagePosition === 'left' ? 'order-2' : 'order-1'}`}>
                    <div className={`niche-body shadow p-3 bg-body rounded position-relative ${props.imagePosition === 'left' ? 'niche-body-right' : 'niche-body-left'}`}>
                        <div className="mb-4 title">{props.title}</div>
                        <p dangerouslySetInnerHTML={{__html: props.body}}></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
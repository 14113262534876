import {useEffect} from "react";

export const Footer = () => {

    const currentYear = new Date().getFullYear()

    return (
        <section id="contact" className="container-fluid footer">
            <div className="row align-items-center py-4">
                <div className="col-12 text-center">
                    <span className="mx-2">&copy; {currentYear} All Rights Reserved | Symos is a product of ASIGMA.</span>
                </div>
            </div>
        </section>
    )
}
import {IFeature} from "./FeatureList";

export const FeatureCard = (props: IFeature) => {
    return (
        <div className="card feature-card p-3 mb-4 text-white" style={{backgroundColor: props.bgColor, borderRadius: props.borderRadius}}>
            <div className="card-body">
                <div className="card-title feature-title">
                    <div className="d-flex">
                        <i className={`bi bi-${props.icon} me-3 feature-icon`}></i>
                    </div>
                    <div className="d-flex">
                        <h4>{props.title}</h4>
                    </div>
                </div>
                <div className="card-text pt-3 feature-body" dangerouslySetInnerHTML={{__html: props.body}}></div>
            </div>
        </div>
    )
}
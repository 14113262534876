import {useEffect} from "react";
import {Widget} from "@typeform/embed-react";

export const GetInTouch = () => {
    return (
        <section id="contact" className="container-fluid get-in-touch">
            <div className="row align-items-center py-5">
                <div className="col-12">
                    {/*<iframe src="https://8zpwe43g1um.typeform.com/to/LRykHvwP"></iframe>*/}
                    <Widget id="oMpjSYcp" style={{width: '100%', height: '100vh'}} />
                </div>
            </div>
        </section>
    )
}
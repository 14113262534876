import React from 'react';

import './App.css';
import {NavBar} from "./components/NavBar";
import {Banner} from "./components/Banner";
import {About} from "./components/About";
import {Features} from "./components/features/Features";
import {Niche} from "./components/niche/Niche";
import {GetInTouch} from "./components/GetInTouch";
import {Footer} from "./components/Footer";

function App() {
    return (
        <>
            <NavBar/>
            <div>
                <Banner/>
                <About/>
                <Features />
                <Niche />
                <GetInTouch />
                <Footer/>
            </div>
        </>
    );
}

export default App;

export const About = () => {
    return (
        <section id="about" className="container">
            <div className="row align-items-center about-wrapper py-5">
                <div className="col-12 col-lg-6">
                    <img src="/images/Graphic 2.png"/>
                </div>
                <div className="col-12 col-lg-5">
                    <strong>ABOUT</strong>
                    <h1 className="my-4">What is Symos?</h1>
                    <p>Symos is an all-in-one solution revolutionizing the way you
                        source, manage, and optimize your agricultural supply chain.
                        Whether you're a farmer, retailer, distributor, or commercial service
                        provider, our comprehensive platform offers unmatched
                        capabilities to streamline your operations and drive success.</p>
                    <p>With Symos, you can source high-quality produce directly from
                        verified farmers and farmer groups, ensuring transparency and
                        reliability in your supply chain. By connecting you directly with
                        producers, we empower farmers through fair pricing and provide
                        them with direct market access, fostering sustainable partnerships
                        and driving economic empowerment.</p>
                </div>
            </div>
        </section>
    )
}
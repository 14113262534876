import {INiche} from "./NicheItem";

export const NicheList : Array<INiche> = [
    {
        title: "Traceability",
        bgColor: "#ffffff",
        borderRadius: "3em 0 0 0",
        icon: 'activity',
        imageSrc: '/images/Traceability.png',
        imagePosition: 'left',
        body: "Symos utilizes data analysis to ensure traceability across the agricultural value chain, " +
            "tracking crop yields, land usage, and farming methods from planting to post-harvest. " +
            "This fosters collaboration between buyers and farmers, promoting sustainable partnerships and mutual growth."
    },
    {
        title: "Operational Efficiency",
        bgColor: "#ffffff",
        icon: 'cpu-fill',
        borderRadius: "0 0",
        imageSrc: '/images/Efficiency.jpg',
        imagePosition: 'right',
        body: "Symos revolutionizes farm operations, automating tasks, centralizing data, " +
            "and offering real-time insights. Optimize planting, resource usage, and crop management for " +
            "higher yields, reduced costs, and sustainable practices."
    },
    {
        title: 'EUDR Compliance',
        bgColor: "#ffffff",
        borderRadius: "0 3em 0 0",
        icon: "command",
        imageSrc: '/images/EUDR.jpg',
        imagePosition: 'left',
        body: "With Symos, you have all the tools you need to show compliance with the European Deforestation Regulation."
    },
    {
        title: 'Offline Access & USSD',
        bgColor: "#ffffff",
        borderRadius: "0 3em 0 0",
        icon: "phone",
        imageSrc: '/images/Offline.png',
        imagePosition: 'right',
        body: "Symos works everywhere. We have built with limited connectivity in mind. Using the mobile application, you can profile all your farmers" +
            " at any time without access to the internet. Farmers can also access the marketplace using feature phones."
    }
]